// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  height: $btn-height;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover() {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    border: solid 1px $gray-500;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

    &:active,
    &.active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-medium;
  color: $link-color;
  text-decoration: $link-decoration;
  padding: 0 0;
  height: unset;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

.focus-link {
  &:focus {
    // border-radius: 2px;
    box-shadow: 0px 0px 0px 4px #25a6da;
    // box-shadow: 0 0 0 4px #7fd0ffaa;
  }
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  font-size: 14px;
  height: 29px;
}
.btn-md {
  font-size: 14px;
  height: 41px;
}
.btn-short {
  height: 1.875rem;
  font-size: 0.875rem;
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

.btn-pagination {
  background-color: $primary;
  color: white;
  &:hover {
    color: white !important;
  }
}

.btn-primary {
  &:hover,
  &.hover {
    background-color: $primary;
    border: solid 1px $primary;
  }
  &:focus,
  &.focus {
    background-color: $primary;
    border: solid 1px $primary;
    box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
  }

  &:active,
  &.active {
    background-color: $primary;
    border: solid 1px $primary;
    box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $gray-500;
    border: solid 1px $gray-500;
  }
}

.btn-outline-primary {
  &:hover,
  &.hover {
    background-color: #cbdfea;
    border: solid 1px $primary;
    color: $primary;
  }
  &:focus,
  &.focus {
    background-color: white;
    border: solid 1px $primary;
    box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
    color: $primary;
  }

  &:active,
  &.active {
    background-color: #84b3ce !important;
    border: solid 1px $primary;
    color: $primary !important;
    box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: white;
    border: solid 1px $gray-500;
    color: #656f72;
  }
}
