.pagination {
  display: flex;
  @include list-unstyled();
  @include border-radius();
}

.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $pagination-bg;
  border: $pagination-border-width solid $brand-blue-500;
  border-radius: 8px;
  margin-right: 16px;
  height: 40px;
  min-width: 40px;
  font-size: 20px;
  font-weight: $font-weight-medium;
  text-align: center;

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.pagination-next .page-link {
  text-indent: -999999px;
  overflow: hidden;
}

.pagination-prev .page-link {
  text-indent: -999999px;
  overflow: hidden;
}

.page-item {
  &:first-child {
    .page-link {
      background-image: url("/assets/icons/prev-page.svg");
      background-position: center;
      // background-size: 8px 16px;
      background-repeat: no-repeat;
    }
  }
  &:last-child {
    .page-link {
      margin-right: 0;
      background-image: url("/assets/icons/next-page.svg");
      background-position: center;
      // background-size: 8px 16px;
      background-repeat: no-repeat;
    }
  }

  &.active .page-link {
    z-index: 3;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}

//
// Sizing
//

.pagination-lg {
  @include pagination-size(
    $pagination-padding-y-lg,
    $pagination-padding-x-lg,
    $font-size-lg,
    $line-height-lg,
    $border-radius-lg
  );
}

.pagination-sm {
  @include pagination-size(
    $pagination-padding-y-sm,
    $pagination-padding-x-sm,
    $font-size-sm,
    $line-height-sm,
    $border-radius-sm
  );
}
