.hch-btn {
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.5rem;
  color: $white;
  background-color: $primary-blue-500;
  border: none;
  outline: none !important;
  cursor: pointer;
  min-width: 88px;
}

.hch-btn-primary {
  &:hover {
    background-color: $primary-blue-400;
  }

  &:active {
    background-color: $primary-blue-700;
  }

  &:disabled {
    background-color: $gray-500;
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px $primary-blue-100;
  }
}

.hch-btn-secondary {
  color: $primary-blue-500;
  background-color: white;
  border: 1px solid $primary-blue-500;

  &:hover {
    background-color: $brand-blue-100;
  }

  &:active {
    background-color: $brand-blue-400;
  }

  &:disabled {
    color: $gray-500;
    border: 1px solid $gray-500;
  }

  &:focus {
    background-color: $white;
    box-shadow: 0px 0px 0px 4px $primary-blue-100;
  }
}

.hch-btn-link {
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  color: $primary-blue-500;
  border: none;
  outline: none !important;
  cursor: pointer;
  background-color: transparent;
  padding: 0;

  &:hover {
    color: #177ba6;
  }

  &:active {
    color: $primary-blue-700;
  }

  &:disabled {
    color: $gray-500;
  }

  &:focus {
    outline: none;
  }
}

.hch-social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;

  img {
    margin-right: 0.5rem;
  }
}

.hch-google-btn {
  color: $primary-blue-500;
  background-color: $white;
  border: 1px solid $primary-blue-500;
  &:hover {
    border: 1px solid $primary-blue-400;
    // color: $primary-blue-400;
  }

  &:active {
    color: $primary-blue-700;
    // border: 1px solid $primary-blue-700;
  }

  &:focus {
    // outline: none;
  }
}

.hch-facebook-btn {
  color: $white;
  background-color: $facebook-brand;
  border: 1px solid $facebook-brand;
  &:hover {
    background-color: $facebook-brand-hover;
    border: 1px solid $facebook-brand-hover;
    color: $white;
  }

  &:active {
    background-color: $facebook-brand-active;
    border: 1px solid $facebook-brand-active;
  }

  &:focus {
    background-color: $facebook-brand-hover;
    border: 1px solid $facebook-brand-hover;
    color: $white;
  }
}

.close-btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  transition: 0.3s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
  &:focus {
    border-radius: 2px;
    box-shadow: 0px 0px 0px 4px #25a6da;
    outline: none;
    // box-shadow: 0 0 0 4px #7fd0ffaa;
  }
}
