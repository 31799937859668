@import 'variables';
@import 'functions';

.fp-tableCell {
  vertical-align: unset;
}
.anchor-container {
  min-height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.full-page {
  min-height: vh(100, calc(-1 * #{$header-height}));

  @media (max-width: $mobile-size) {
    min-height: vh(100, calc(-1 * #{$mobile-nav-height}));
  }
}
