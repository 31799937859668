@import 'variables';
@import 'colors';
@import 'button';

.auth-modal {
  width: 25.875rem;
  @media (max-width: $mobile-size) {
    width: auto;
  }
  font-family: $font-family-montserrat;

  .modal-content {
    border: none;
  }

  &__content {
    position: relative;
    padding: 1.875rem;
    background: $white;
    box-shadow: 0px 0.25rem 1.25rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    border: none;

    .close-btn {
      display: flex;
      align-items: center;
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 1rem;
      height: 1rem;
      padding: 0;
      background: transparent;
      border: none;
      outline: none;
      transition: 0.3s all ease;
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }

    .step {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .or {
      display: flex;
      align-items: center;

      span {
        color: $brand-blue-500;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      hr {
        width: 8.125rem;
        border-top: 1px solid $brand-blue-500;
        margin: 0;
      }
    }

    .social-btns {
      width: 100%;
      .hch-social-btn {
        margin-bottom: 1.5rem;
        width: 100%;
      }
    }
  }
}

.auth-form {
  width: 100%;

  .form-group {
    position: relative;
    margin-bottom: 2rem;
    input,
    textarea {
      width: 100%;
      padding: 0.75rem 1rem;
      background: $white;
      border: 1px solid $brand-blue-500;
      border-radius: 0.5rem;
      outline: none;
      transition: 0.1s ease-out;

      &.error-field {
        border: 1px solid $red-500;
      }

      &:focus {
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #fff;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #fff;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #fff;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #fff;
        }
      }
      &:read-only {
        cursor: no-drop;
      }
    }

    label {
      position: absolute;
      left: 0.75rem;
      top: 21px;
      transform: translateY(-50%);
      padding: 0 0.25rem;
      transition: 0.1s ease-out;
      transform-origin: left top;
      pointer-events: none;
      font-family: $font-family-montserrat;
      color: $gray-500;
      background-color: white;
    }

    input:focus + label,
    textarea:focus + label {
      top: 0;
      transform: translateY(-50%);
      opacity: 1;
    }

    textarea:not(:placeholder-shown) + label,
    input:not(:placeholder-shown) + label {
      top: 0;
      transform: translateY(-50%);
      opacity: 1;
    }

    // textarea:not(:focus):placeholder-shown + label,
    // input:not(:focus):placeholder-shown + label {
    //   opacity: 0;
    // }

    .error {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: -1.5rem;
      left: 0;

      .alert-img {
        margin-right: 0.5rem;
      }

      .error-text {
        color: $red-500;
      }
    }

    &.password-field {
      .visible-btn {
        position: absolute;
        top: 0.625rem;
        right: 1rem;
        padding: 0;
        outline: none;
        border: none;
        background: transparent;
      }
    }
  }

  .form-btn {
    width: 100%;
  }
}
