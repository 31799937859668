h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 140%;
  letter-spacing: 0.1em;
  margin: 0;
  color: $black;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}

h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
  color: $black;
}

h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}

.text-xxs {
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 15px;
  margin: 0;
  color: $black;
}

.text-xxl {
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}

.text-xl {
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 140%;
  margin: 0;
  color: $black;
}

.text-l {
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
  color: $black;
}

.text-m {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  margin: 0;
  color: $black;
}

.text-s {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 17px;
  margin: 0;
  color: $black;
}

.text-xs {
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}

.text-xs-italit {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 140%;
  margin: 0;
  color: $black;
}

.text-xxs-italic {
  font-style: italic;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 140%;
  margin: 0;
  color: $black;
}

.text-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  margin: 0;
  color: $black;
}
