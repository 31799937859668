.hamburger-icon {
  width: 24px;
  height: 24px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: #656f72;
    border-radius: 1px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 4px;
      width: 24px;
      right: 0;
    }

    &:nth-child(2) {
      top: 12px;
      width: 18px;
    }

    &:nth-child(3) {
      top: 20px;
      width: 12px;
    }
  }
  &.blue {
    span {
      background-color: $primary-blue-500;
    }
  }
  &.white {
    span {
      background-color: $white;
    }
  }
}

.hamburger-icon[aria-expanded="true"] {
  span {
    &:nth-child(1) {
      top: 12px;
      width: 24px;
      transform: rotate(135deg);
    }

    &:nth-child(2) {
      opacity: 0;
      // left: -30px;
    }

    &:nth-child(3) {
      top: 12px;
      width: 24px;
      transform: rotate(-135deg);
    }
  }
}
