.gm-style .gm-style-iw-c {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
// .gm-style .gm-style-iw-d {
//   overflow: auto !important;
// }

button.gm-ui-hover-effect {
  display: none !important;
}
