@import 'variables';

.panel-group {
  .panel.card {
    border: none;
    background-color: transparent;

    &.none-toggle {
      .accordion-toggle::after {
        content: unset !important;
      }
    }
    .panel-heading {
      width: 100%;
      align-items: center;
      display: flex;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      border-bottom: none;
      .panel-title {
        width: 100%;
      }
      .accordion-toggle {
        padding: 7px;
        width: 100%;
        display: flex;
        align-items: center;
        color: $primary-blue-500;
        position: relative;
        &::after {
          position: absolute;
          right: 8px;
          display: inline-block;
          margin-left: 8px;
          content: '';
          background-image: url('/assets/images/icons/chevron-down.svg');

          // z-index: 10;
          width: 11px;
          height: 7px;
          background-repeat: no-repeat;

          transition: transform 0.25s linear;
          -webkit-transition: -webkit-transform 0.25s linear;
        }
        .menu-icon {
          width: 26px;
          margin-right: 27px;
        }
        &[aria-expanded='true'] {
          &::after {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
    }

    .panel-body {
      padding: 0;
      min-height: unset;
    }
  }
}
