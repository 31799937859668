@import 'variables';

.page-content {
  position: relative;
  padding-top: $header-height;

  @media (max-width: $mobile-size) {
    padding-top: 0;
  }
}
.page-content-wrapper {
  @media (max-width: $mobile-size) {
    padding-bottom: 56px;
  }
}
