$primary-blue-50: #46B7E6;
$primary-blue-100: #25A6DA;
$primary-blue-200: #008FC9;
$primary-blue-300: #056DAE;
$primary-blue-400: #056DAE;
$primary-blue-500: #002A54;
$primary-blue-600: #002A54;
$primary-blue-700: #002A54;
$primary-blue-800: #002A54;
$primary-blue-900: #002A54;

$brand-blue: #edf4f8;
$brand-blue-50: #d8e7f0;
$brand-blue-100: #cbdfea;
$brand-blue-200: #bad4e4;
$brand-blue-300: #a3c6da;
$brand-blue-400: #84b3ce;
$brand-blue-500: #002A54;
$brand-blue-600: #002A54;
$brand-blue-700: #002A54;
$brand-blue-800: #002A54;
$brand-blue-900: #002A54;

$accent-green-50: #dafbab;
$accent-green-100: #cdfa8e;
$accent-green-200: #bdf869;
$accent-green-300: #a6f637;
$accent-green-400: #85dd0a;
$accent-green-500: #4f8406;
$accent-green-600: #426610;
$accent-green-700: #375311;
$accent-green-800: #2f4411;
$accent-green-900: #273711;

$accent-yellow-50: #feeccf;
$accent-yellow-100: #fee6bf;
$accent-yellow-200: #fddda9;
$accent-yellow-300: #fdd28d;
$accent-yellow-400: #fcc367;
$accent-yellow-500: #fbb034;
$accent-yellow-600: #e29720;
$accent-yellow-700: #b97f22;
$accent-yellow-800: #976b23;
$accent-yellow-900: #7b5923;

$red-50: #fbbfb2;
$red-100: #faaa98;
$red-200: #f88d75;
$red-300: #f66848;
$red-400: #f1360c;
$red-500: #a02308;
$red-600: #7b2714;
$red-700: #642415;
$red-800: #522015;
$red-900: #431d15;

$gray-50: #daddde;
$gray-100: #ced2d3;
$gray-200: #bdc3c5;
$gray-300: #a7afb1;
$gray-400: #8a9497;
$gray-500: #656f72;
$gray-600: #575e60;
$gray-700: #4a5051;
$gray-800: #404345;
$gray-900: #36393a;

$black: #212429;
$facebook-brand: #4267b2;
$facebook-brand-hover: #3b5998;
$facebook-brand-active: #29487d;