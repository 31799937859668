// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$home-captain-blue-palette: (
  900: #002a54,
  800: #033a6c,
  700: #0b4478,
  600: #184e83,
  500: #20558c,
  400: #496d9a,
  300: #496d9a,
  200: #94a8c3,
  100: #becadb,
  50: #e5eaf0,
  contrast: (
    900: rgba(black, 0.87),
    800: rgba(black, 0.87),
    700: rgba(black, 0.87),
    600: rgba(black, 0.87),
    500: rgba(black, 0.87),
    400: rgba(black, 0.87),
    300: rgba(black, 0.87),
    200: rgba(black, 0.87),
    100: rgba(black, 0.87),
    50: rgba(black, 0.87),
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hchv2-primary: mat.define-palette($home-captain-blue-palette);
$hchv2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hchv2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hchv2-theme: mat.define-light-theme(
  (
    color: (
      primary: $hchv2-primary,
      accent: $hchv2-accent,
      warn: $hchv2-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hchv2-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '../node_modules/bootstrap/scss/functions';
// @import '../node_modules/bootstrap/scss/variables';
// @import '../node_modules/bootstrap/scss/bootstrap';
// @import '../node_modules/bootstrap/scss/mixins';
// @import '~bootstrap/scss/bootstrap';
// @import './styles/index';
@import './styles/hch';

// @import url(~fullpage.js/dist/fullpage.min.css);

body {
  font-family: $font-family-montserrat;
  margin: 0;
}

a[href^='https://dos.ny.gov']
{
  color: white !important;
  text-decoration: underline;
  margin-top: 1rem;
}

// Styles for google maps
.hch-marker {
  background-image: url('./assets/images/buy/cluster-imgs/m1.png');
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Style overrides for date picker
.bs-datepicker-body table td.week span {
  color: $primary !important;
}
bs-datepicker-container {
  z-index: 500;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*='select-']:after,
.bs-datepicker-body table td[class*='select-'] span:after,
.bs-datepicker-body table td.active-week span:hover {
  background-color: $primary !important;
}

.mat-option {
  font-family: $font-family-montserrat;
}
.mat-mdc-select {
  border: 1px solid #056dae;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.cdk-overlay-container {
  z-index: 1500;
}
.cdk-overlay-pane {
  z-index: 2000;
}

.panel.card {
  .panel-heading.card-header {
    // border-top: 1px solid #c2dbe8;
    border-bottom: 1px solid #c2dbe8;
  }
}

.letter-spacing {
  letter-spacing: 4px;

  .first-letter {
    font-size: 2.5rem;
  }
}

// html,
// body {
//   height: 100%;
// }
// body {
//   margin: 0;
//   font-family: Roboto, 'Helvetica Neue', sans-serif;
// }
