// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;
  text-decoration: if($link-decoration == none, null, none);

  @include hover-focus() {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.large {
  .nav-tabs {
    max-width: 50%;
    @media (max-width: 1024px) {
      max-width: 75%;
    }
    @media (max-width: $mobile-size) {
      max-width: 100%;
    }
    .nav-item {
      .nav-link {
        span {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.nav-tabs {
  // border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  background-color: #edf4f8;
  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
    position: relative;
    display: block;
    flex: 1;
    &::after {
      content: '';
      position: absolute;
      right: -16px;
      width: 16px;
      top: 0px;
      bottom: 0;
      background-color: white;
      border-top-left-radius: 8px;
      z-index: 2;
    }
    &::before {
      content: '';
      position: absolute;
      left: -16px;
      width: 16px;
      top: 0px;
      bottom: 0;
      background-color: white;
      border-top-right-radius: 8px;
      z-index: 2;
    }

    &:first-child {
      &::before {
        content: unset;
        display: none;
      }

      .nav-link.active,
      .nav-item.show .nav-link {
        &::before {
          content: unset;
          display: none;
        }
      }
    }
    &:last-child {
      &::after {
        content: unset;
        display: none;
      }

      .nav-link.active,
      .nav-item.show .nav-link {
        &::after {
          content: unset;
          display: none;
        }
      }
    }
  }

  .nav-link {
    white-space: nowrap;
    border: $nav-tabs-border-width solid transparent;
    font-size: 14px;
    background-color: #edf4f8;
    padding: 18px;
    @include border-top-radius($nav-tabs-border-radius);
    // @include hover-focus() {
    //   border-color: $nav-tabs-link-hover-border-color;
    // }
    display: flex;
    justify-content: center;

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: #212429;
    font-weight: 500;
    background-color: $nav-tabs-link-active-bg;
    // border-color: $nav-tabs-link-active-border-color;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -17px;
      width: 16px;
      top: -1px;
      bottom: -1px;
      background-color: #edf4f8;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      z-index: 3;
    }

    &::before {
      content: '';
      position: absolute;
      left: -17px;
      width: 16px;
      top: -1px;
      bottom: -1px;
      background-color: #edf4f8;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      z-index: 3;
    }
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}

//
// Pills
//

.nav-pills {
  .nav-link {
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
    // padding: 20px 50px;
    padding: 12px;
  }
  > .active {
    display: block;
  }
}
