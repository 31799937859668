.switch {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  width: 48px;
  height: 32px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:focus ~ .slider {
      box-shadow: 0px 0px 0px 4px #25a6da;
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-500;
  transition: 0.4s;

  border-radius: 32px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 28px;
  width: 28px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;

  border-radius: 50%;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  transform: translateX(16px);
}
